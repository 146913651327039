import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
// 

import pic08 from '../assets/images/pic08.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'


const Project = ({data}) => (
    <Layout>
        <Helmet>
            <title>{data.airtable.data.Title}</title>
            <meta name="description" content="" />
        </Helmet>

        <div id="main" className='alt'>
        <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>{data.airtable.data.Title}</h1>
                    </header>

                    <div className="grid-wrapper">
                        <div className="col-6">
                            {/* <h3>Sem turpis amet semper</h3> */}
                            <p className='intro'>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat commodo eu sed ante lacinia. Sapien a lorem in integer ornare praesent commodo adipiscing arcu in massa commodo lorem accumsan at odio massa ac ac. Semper adipiscing varius montes viverra nibh in adipiscing blandit tempus accumsan.</p>
                            <p className=''><span className='intro'>Stack: </span>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus.</p>
                        <ul className='actions'>
                        <li><a href="#" className="button">Default</a></li>
                        <li><a href="#" className="button">Default</a></li>
                        </ul>
                        </div>
                        <div className="col-6">
                        <span className="image fit"><img src={pic03} alt="" /></span>
                        </div>
                    </div>
                    <hr className="major" />
                    <span className="image fit"><img src={pic03} alt="" /></span>
                    <hr className="major" />
                     
                    <div className="grid-wrapper">
                        <div className="col-9">
                            <h3>Project Purpose and Goals</h3>
                            <p className='intro'>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat commodo eu sed ante lacinia. Sapien a lorem in integer ornare praesent commodo adipiscing arcu in massa commodo lorem accumsan at odio massa ac ac. Semper adipiscing varius montes viverra nibh in adipiscing blandit tempus accumsan.</p>
                            <p className=''><span className='intro'>Stack: </span>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus.</p>
                        </div>
                        
                    </div>
                    <hr className="major" />

                    <div className="grid-wrapper">
                       
                        {/* <div className="col-6"> */}
                        <div className="box alt col-5">
                                    <div className="grid-wrapper" style={{gap : "30px"}}>
                                        <div className="col-4"><span className="image fit"><img src={pic08} alt="" /></span></div>
                                        <div className="col-4"><span className="image fit"><img src={pic09} alt="" /></span></div>
                                        <div className="col-4"><span className="image fit"><img src={pic10} alt="" /></span></div>
                                        <div className="col-4"><span className="image fit"><img src={pic10} alt="" /></span></div>
                                        <div className="col-4"><span className="image fit"><img src={pic08} alt="" /></span></div>
                                        <div className="col-4"><span className="image fit"><img src={pic08} alt="" /></span></div>

                                    </div>
                                
                        {/* </div> */}
                        </div>
                        <div className="col-6">
                            <h3>Stack Usage</h3>
                            <p className='intro'>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat commodo eu sed ante lacinia. Sapien a lorem in integer ornare praesent commodo adipiscing arcu in massa commodo lorem accumsan at odio massa ac ac. Semper adipiscing varius montes viverra nibh in adipiscing blandit tempus accumsan.</p>
                           
                        </div>
                    </div>
                    <hr className="major" />

                    <h3>Screenshots</h3>

                    <div className="grid-wrapper">
                       
                        <div className="col-12">
                        <div className="box alt">
                                    <div className="grid-wrapper" style={{gap : "30px"}}>
                                        <div className="col-6"><span className="image fit"><img src={pic08} alt="" /></span></div>
                                        <div className="col-6"><span className="image fit"><img src={pic09} alt="" /></span></div>
                                        <div className="col-6"><span className="image fit"><img src={pic10} alt="" /></span></div>
                                        <div className="col-6"><span className="image fit"><img src={pic10} alt="" /></span></div>
                                    </div>
                                
                        </div>
                        </div>
                    </div>
                    <hr className="major" />
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <h3>Testing</h3>
                            <p className='intro'>
                            Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non</p>
                            <p>
                            <b>Accessibility: </b>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non <br/><b>Responsiveness: </b>faucibus ornare mi ut ante amet placerat aliquet.<br/>
                            <b>Performance: </b>Volutpat commodo eu sed ante lacinia. Sapien a lorem in integer ornare praesent commodo adipiscing arcu in<br/> 
                            <b>Usability: </b>Volutpat commodo eu sed ante lacinia. Sapien a lorem in integer ornare praesent commodo adipiscing arcu in 
                            </p>
                        </div>
                        <div className="col-6">
                        <span className="image fit"><img src={pic03} alt="" /></span>
                        </div>
                    </div>

                    <hr className="major" />

                    <h3>Problems faced & Thought Process</h3>

                    <div className="grid-wrapper">
                       
                        <div className="col-12">
                        <p className='intro'>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat commodo eu sed ante lacinia. Sapien a lorem in integer ornare praesent commodo adipiscing arcu in massa commodo lorem accumsan at odio massa ac ac. Semper adipiscing varius montes viverra nibh in adipiscing blandit tempus accumsan.</p>
                        </div>
                    </div>
                    <hr className="major" />
                    <span className="image fit"><img src={pic03} alt="" /></span>
                    <hr className="major" />

                    <h3>Key Takeaways</h3>

                    <div className="grid-wrapper">
                       
                        <div className="col-12">
                        <p className='intro'>Nunc lacinia ante nunc ac lobortis. Interdum adipiscing gravida odio porttitor sem non mi integer non faucibus ornare mi ut ante amet placerat aliquet. Volutpat commodo eu sed ante lacinia. Sapien a lorem in integer ornare praesent commodo adipiscing arcu in massa commodo lorem accumsan at odio massa ac ac. Semper adipiscing varius montes viverra nibh in adipiscing blandit tempus accumsan.</p>
                        </div>
                    </div>

                    <hr className="major" />

                    <h3>Other Projects</h3>

                    <div className="grid-wrapper">
                       
                        {/* <div className="col-4"> */}
                                        <div className="col-4"><span className="image fit"><img src={pic08} alt="" /></span></div>
                                        <div className="col-4"><span className="image fit"><img src={pic09} alt="" /></span></div>
                                        <div className="col-4"><span className="image fit"><img src={pic09} alt="" /></span></div>
                                        </div>
                    {/* </div> */}
                    
                </div>
            </section>
        </div>
    </Layout>
)

export const query = graphql`
query SpaceQuery {
    airtable(table: {eq: "tbliU3QHg1Vd23L3M"}, data: {ID: {eq: 2}}) {
      data {
        ID
        Accessibility
        GithubURL
        IntroPic
        Introduction
        LiveURL
        Permalink
        Title
      }
    }
  }
  `

export default Project